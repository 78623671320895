import { useEffect, useCallback } from 'react';

export const useTimeUtils = () => {


    const toHHMMSS = useCallback((secs) => {
        if (!secs)
            return "";
        let sec_num = parseInt(secs, 10)
        let hours = Math.floor(sec_num / 3600)
        let minutes = Math.floor(sec_num / 60) % 60
        let seconds = sec_num % 60
        return [hours, minutes, seconds]
            .map(v => v.toString().padStart(2, "0"))
            .join(":")
    }, []);

    const formatDate = useCallback((date) => {
        if (!date)
            return "";
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }, []);

    const validateDate = useCallback((date) => {
        try {
            new Date(date).toISOString()
            return true
        } catch (e) {
            return false
        }

    }, []);


    return { toHHMMSS, formatDate, validateDate };
}